import * as React from 'react';
import Layout from '../components/layout/Layout';
import NavBar from '../components/navbar/NavBar';
import Privacy from '../components/privacy/Privacy';
import SEO from '../components/seo/Seo';

const PrivacyPage = () => {
    return (
        <Layout isPrivacyPolicy={true}>
            <SEO title="Privacy" />
            <NavBar />
            <Privacy />
        </Layout>
    );
};

export default PrivacyPage;
