import React, {useState} from 'react';
import Logo from '../../images/logo_22.svg';
import CalendarIcon from '../../images/calendar.svg';
import TicketIcon from '../../images/ticket.svg';
import MenuIcon from '../../images/menu.svg';
import './navbar.scss'
import Socials from "../socials/Socials";

const NavBar = () => {
    const [showMenu, setShowMenu] = useState('');

    const toggleMenu = () => {
        if (showMenu === '') {
            setShowMenu('show');
        } else {
            setShowMenu('');

        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <img src={MenuIcon} alt="Menu"/>
                </button>
                <a className="navbar-brand" href="/">
                    <img src={Logo} alt="Refactor"/>
                </a>
                <div className={'collapse navbar-collapse ' + showMenu} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" href="/schedule">
                                <img src={CalendarIcon} alt="Schedule"/>
                                <span className="nav-item-text">SCHEDULE</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/tickets">
                                <img src={TicketIcon} alt="Tickets & Donate"/>
                                <span className="nav-item-text">TICKETS & DONATE</span>
                            </a>
                        </li>
                    </ul>
                    <div className="socials-header">
                        <Socials dark={true}/>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;
