import React from "react";
import Logo from "../../images/logo_22.svg";
import { Link } from "gatsby";

const Privacy = () => {
  return (
    <div id="privacy-policy">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <img className="w-100 w-sm-75" alt="logo" src={Logo} />
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col">
            <h1 className="">Privacy policy Re:factor</h1>
            <p>
              By accessing and using the website, you have expressly agreed to
              the following general conditions. The contents of this site,
              including trademarks, logos, drawings, data, product or company
              names, texts, images, etc. are protected by intellectual property
              rights and belong to the Re:factor organizers Axxes, Euricom and
              Painting With Light.
            </p>
            <p>
              This privacy policy has as purpose to disclose what personal data
              Re:factor collect about our users. This privacy policy is in place
              in order to make it easy and understandable for our customers how
              we process personal data and also contains information about the
              rights you have concerning your personal data.
            </p>
            <p>
              Re:factor processes personal data relating to customers and other
              persons who have contacted or interacted with Re:factor in
              connection with our services. The different types of personal data
              along with it’s required legal grounds for processing and its
              specific purposes are further described in the following of this
              Privacy Policy.
            </p>
            <h2>About the privacy policy</h2>
            <p>
              The organizers of Re:factor believe that your privacy is
              important. The Re:factor organizers wish to inform the user of our
              website about possible processing operations and the rights of the
              user.
            </p>
            <h2>Our collection and use of personal data</h2>
            <p>
              Depending of what service you use us for, we process different
              types of persona data for different purposes.
            </p>
            <p>
              The following personal data might be collected and processed by
              the Re:factor organizers. This information will be used to ensure
              a better service:
              <ul>
                <li>First and last name</li>
                <li>Company information</li>
                <li>Job title</li>
                <li>E-mail</li>
                <li>Phone number</li>
                <li>IP address</li>
              </ul>
            </p>
            <p>
              The Re:factor organizers can collect non-personal anonymous or
              aggregate data such as browser type, operating system in use,
              and/or the domain name of the website that led you to and from our
              website, to ensure optimum effectiveness of our website for all
              users. The user may, free of charge and on request, always prevent
              the use of his personal details for the purposes of direct
              marketing.
            </p>
            <p>
              The personal data that is being collected might be processed for
              one of the following purposes:
              <ul>
                <li>To maintain an updated customer database</li>
                <li>
                  To keep our users informed of our activities and new events
                </li>
                <li>
                  To request feedback on Re:factor services or to send surveys
                </li>
                <li>For the purpose of marketing</li>
              </ul>
            </p>
            <h2>The use of cookies</h2>
            <p>
              During a visit to the site, cookies may be placed on the hard
              drive of your computer with the user’s consent. This is done to
              ensure that our site is optimized to the needs of users returning
              to our website. These cookies are not used to ascertain the
              surfing habits of the visitor on other websites. Your internet
              browser enables you to disable these cookies, receive a warning
              when a cookie has been installed or have the cookies removed from
              your hard disk. For this purpose, consult the help function of
              your internet browser.
            </p>
            <p>
              The information analyzed by Google Analytics is governed by the
              Privacy Policy of Google Analytics, as found{" "}
              <a
                href="https://policies.google.com/privacy?hl=en&gl=ZZ"
                rel="noopener"
                target="_blank"
              >
                here
              </a>{" "}
              The lawful basis for this processing is GDPR article 6 no.1 litra
              f) (a legitimate interest in processing when our interest is not
              overridden by the data subjects interests)
            </p>
            <h2>Storage</h2>
            <p>
              We keep the personal data as long as it is necessary for the
              specific purpose of the processing of personal data as listed
              above under the use and collection section. Personal data
              processed for the purpose of sending out newsletters will be
              stored for as long as the subscriber is still subscribing to the
              newsletter.
            </p>
            <p>
              Personal data will not be stored for longer time than the customer
              wished and can at all times be requested deleted.
            </p>
            <h2>Information security</h2>
            <p>
              <span className="highlight">
                We use SSL encryption on our website in order to make the
                personal data we process safe.
              </span>
            </p>
            <h2>Transfer to third parties</h2>
            <p>
              The Re:factor organizers have access to the personal details
              needed for communications and can send updates and information
              about the conference and similar services. Re:factor does not sell
              personal details to third parties. Your personal details will not
              transferred to any third party, except if the user agrees to leave
              his personal details at sponsor booths, if he wishes to use the
              Re:factor matchmaking tools or wishes to receive live
              notifications.
            </p>
            <p>
              If we share any personal data with third parties they are to be
              listed in the following. How they process personal data can be
              read in their terms of service and privacy policies.
              <ul>
                <li>
                  <a
                    href="https://policies.google.com/privacy?hl=en&gl=ZZ"
                    rel="noopener"
                    target="_blank"
                  >
                    Google
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/legal/FB_Work_Privacy"
                    rel="noopener"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/en/privacy"
                    rel="noopener"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://bit.ly/3cwISim"
                    rel="noopener"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/legal/privacy-policy"
                    rel="noopener"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://mailchimp.com/legal/privacy/"
                    rel="noopener"
                    target="_blank"
                  >
                    Mailchimp
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-privacy-policy "
                    rel="noopener"
                    target="_blank"
                  >
                    Eventbrite
                  </a>
                </li>
              </ul>
            </p>
            <h2>User rights</h2>
            <p>
              As a customer of our services and in accordance with required
              legislation you have rights connected to the processing of your
              personal data listed above.
            </p>
            <h4>Right to access</h4>
            <p>
              You as a user can at any time request access to which personal
              data that is stored and processed about you.
            </p>
            <h4>Right to be forgotten</h4>
            <p>
              You can at all times upon request ask us to delete the personal
              data concerning you in circumstances such as where we no longer
              need it or if you withdraw your consent.
            </p>
            <h4>Right to rectification</h4>
            <p>
              If the personal data we have about you is inaccurate or
              incomplete, you are entitled to have it rectified.
            </p>
            <h2>Withdrawal or consent</h2>
            <p>
              You can at any time withdraw your previously given consent as
              legal basis for processing your personal data.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              We have reserved the right to make changes to this policy when we
              find it necessary or required by legislation and you will always
              find the updated version on our website.
            </p>
            <h2>Questions or complaints?</h2>
            <p>
              For any questions you can contact{" "}
              <a href="mailto:info@re-factor.be">info@re-factor.be</a>
            </p>
            <p>Last Updated: Februari 3, 2021</p>
            <Link to="/">Return to home</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
